import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col } from '@commonsku/styles';

import { closePopup } from '../actions/popup';

import { BASE_ZINDEX } from '../popup-factory';
import { getImageSrc } from '../utils';

class DisplayArtworkPopup extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { index, onClosePopup, file } = this.props;

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible', transition: 'width 0.1s, height 0.1s' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <h3 id="modalTitle"></h3>
          <div className="small-12">
            <a className="alert button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Close</a>
          </div>
        </div>
        <Row style={{ justifyContent: 'center' }}>
          <div style={{ height: '500px' }}>
            <img src={getImageSrc(file, 'fallback')} style={{ maxHeight: '100%' }} />
          </div>
        </Row>
        <Row style={{ justifyContent: 'center', overflowWrap: 'break-word' }}>
          <Col sm={12} style={{ textAlign: 'center' }}>{file.file_display_name}</Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedDisplayArtworkPopup = connect(mapStateToProps, mapDispatchToProps)(DisplayArtworkPopup);
export default ConnectedDisplayArtworkPopup;
